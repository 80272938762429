// Include the different bits needed in this page
// @codekit-prepend "./util/util"
// @codekit-prepend "./shared/stick"
// @codekit-prepend "./lib/swiper.min.js"


let Home = {

    sliderTitle : document.querySelector('.slider-home-text'),

    // Liftoff Function
    liftoff : function() {
        this.initSliderIntro();
        this.initSliderLatest();
    },


    // Initialize the intro slider
    initSliderIntro : function(){
        setInterval(function(){

            let active = document.querySelector('.slide-active')
            let next   = active.nextElementSibling.classList.contains('slider-home-slide') ? active.nextElementSibling : active.parentElement.firstElementChild
            let title  = next.dataset.title
            next.classList.add('slide-active')
            active.classList.remove('slide-active')

            Home.sliderTitle.innerHTML = title

        } , 5000)

    },


    // Latest news slider
    initSliderLatest : function(){

        var elem = document.querySelector('.flickity-latest');
        var flkty = new Flickity( elem, {
            
            // options
            cellAlign       : 'left',
            contain         : true,
            cellSelector    : '.latest-slide',
            pageDots        : false,
            prevNextButtons : true,
            imagesLoaded    : true,
            arrowShape      : "m68.8425277 0 3.6572417 3.25574375L33.8455689 49.9975485l38.6542807 46.7469016L68.8424476 100 28.8460709 51.6253234l-1.3459205-1.6278591 1.3460006-1.6277877z"

        });

    }
};

Home.liftoff();