let Util = {

    // fetch all the items we'll use
    menu   : document.querySelector('.menu'),
    btn    : document.querySelector('.menu-btn'),
    header : document.querySelector('.header'),
    main   : document.querySelector('main'),



    // Kick in the two functions
    liftoff : function () {
        this.toggleMenu();
        this.shrinkHeader();
    },



    // Toggle the navigation on mobile
    toggleMenu : function () {
        this.btn.addEventListener('click' , (event) => {
            this.menu.classList.toggle('is-active');
            this.btn.classList.toggle('is-active');
        });
    },



    // Scale down the header on scroll
    shrinkHeader : function () {

        // Add the listenr on the scroll event
        document.addEventListener('scroll' , (event) => {

            // Grab the main distance to the top
            let distance = this.main.getBoundingClientRect().top;

            // If the distance is higher than 0 and the header doesn't already have the class add it
            if (distance < 0 && !this.header.classList.contains('is-shrink')) {
                this.header.classList.add('is-shrink');
                return;
            }

            // If the distance is lower than 0 and the header does have the 
            if (distance >= 0 && this.header.classList.contains('is-shrink')) {
                this.header.classList.remove('is-shrink');
                return;
            }

        });

    }

};


Util.liftoff();