// ---------------------------------------
// Header Stick Script
// ---------------------------------------
let Stick = {

    // fetch all the items we'll use
    header : document.querySelector('.header'),
    main   : document.querySelector('main'),

    init : function (msg)
    {

        // Attach the two functions to the scroll listener
        document.addEventListener('scroll' , (event) => this.stickyHeader());

        // Log the Message
        console.log(msg);
    },



    // Stick the header to the top
    stickyHeader : function() {
        let offset = this.main.getBoundingClientRect().top;
        if (offset <= 0 && !this.header.classList.contains('is-sticky')) {
            this.header.classList.add('is-sticky');
        } else if (offset > 0 && this.header.classList.contains('is-sticky')){
            this.header.classList.remove('is-sticky');
        };
    }

};

// Kick in the header script
Stick.init('STICK : Go!');